import React from "react";
import {MachineType} from "../../typings";

type Props = {
    machineType?: MachineType;
    setMachineType: (value: MachineType) => void;
    availableMachineTypes: MachineType[];
}

export const MachineTypeSelector: React.FC<Props> = ({ machineType, setMachineType, availableMachineTypes}) => {

    return (
        <div className="mb-30">
            <div className="fw-bolder mb-20">
                01. Select your machine type:
            </div>

            {availableMachineTypes.findIndex((type) => type === "bruker") !== -1 && (
                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        id="bruker"
                        checked={machineType === "bruker"}
                        onChange={(e) => setMachineType(e.target.value as MachineType)}
                        value="bruker"
                    />
                    <label className="form-check-label" htmlFor="bruker">
                        Bruker Biotyper
                    </label>
                </div>
            )}
            {availableMachineTypes.findIndex((type) => type === "shimadzuAscii") !== -1 && (
                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        id="shimadzuVitekMSAscii"
                        checked={machineType === "shimadzuAscii"}
                        onChange={(e) => setMachineType(e.target.value as MachineType)}
                        value="shimadzuAscii"
                    />
                    <label className="form-check-label" htmlFor="shimadzuVitekMSAscii">
                        Shimadzu 8020 / 8030 (ascii)
                    </label>
                </div>
            )}
            {availableMachineTypes.findIndex((type) => type === "clover") !== -1 && (
                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        id="clover"
                        checked={machineType === "clover"}
                        onChange={(e) => setMachineType(e.target.value  as MachineType)}
                        value="clover"
                    />
                    <label className="form-check-label" htmlFor="clover">
                        Clover BioSoft
                    </label>
                </div>
            )}
            {availableMachineTypes.findIndex((type) => type === "vitekMSMzml") !== -1 && (
                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        id="vitekMS"
                        checked={machineType === "vitekMSMzml"}
                        onChange={(e) => setMachineType(e.target.value as MachineType)}
                        value="vitekMSMzml"
                        required={true}
                    />
                    <label className="form-check-label" htmlFor="vitekMS">
                        Vitek MS (mzml)
                    </label>
                </div>
            )}
            {availableMachineTypes.findIndex((type) => type === "vitekMSAscii") !== -1 && (
                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        id="vitekMsPrime"
                        checked={machineType === "vitekMSAscii"}
                        onChange={(e) => setMachineType(e.target.value as MachineType)}
                        value="vitekMSAscii"
                    />
                    <label className="form-check-label" htmlFor="vitekMSAscii">
                        Vitek MS Prime (ascii)
                    </label>
                </div>
            )}
        </div>
    )
}