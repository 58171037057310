import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpFromSquare,
  faBookOpenCover,
  faList,
  faArrowRightFromBracket,
    faUser,
} from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ReactComponent as Logo } from "../theme/images/logo.svg";
import {
  ACCOUNT_ROUTE,
  IDENTIFICATION_UPLOAD, MOSQUITO_VECTORS_UPLOAD,
  ORDERS_ROUTE,
  SPECTRA_QUALITY_UPLOAD,
  TUTORIAL_ROUTE,
} from "../Routes";
import { logout } from "../firebase";
import "./Header.scss";

export const Header: React.FC = () => {
  const handleLogout = async () => {
    await logout();
  };

  return (
    <header className="site-header bg-white">
      <nav className="navbar navbar-expand-xl navbar-light py-lg-0">
        <div className="container justify-content-between">
          <NavLink to="/" className="navbar-brand py-lg-40">
            <Logo />
          </NavLink>

          <button className="navbar-toggler hamburger hamburger--simple" type="button" data-bs-toggle="collapse"
                  data-bs-target="#header-nav-primary" aria-controls="header-nav-primary" aria-expanded="false"
                  aria-label="Toggle navigation">
            <div className="inner">
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </div>
          </button>

          <div
            id="header-nav-primary"
            className="collapse navbar-collapse justify-content-lg-end align-self-lg-center align-items-center pt-40 pt-xl-0"
          >
            <ul
              id="menu-primary"
              className="navbar-nav flex-lg-wrap align-items-lg-stretch"
            >
              <li
                itemScope={true}
                itemType="https://www.schema.org/SiteNavigationElement"
                id="menu-item-338"
                className="nav-item"
              >
                <NavLink
                  title="Identification upload"
                  to={IDENTIFICATION_UPLOAD}
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                >
                  <FontAwesomeIcon icon={faArrowUpFromSquare as IconProp} />
                  Identification
                </NavLink>
              </li>
              <li
                  itemScope={true}
                  itemType="https://www.schema.org/SiteNavigationElement"
                  id="menu-item-338"
                  className="nav-item"
              >
                <NavLink
                    title="Mosquito egg upload"
                    to={MOSQUITO_VECTORS_UPLOAD}
                    className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                    }
                >
                  <FontAwesomeIcon icon={faArrowUpFromSquare as IconProp} />
                  Mosquito Vectors
                </NavLink>
              </li>
              <li
                itemScope={true}
                itemType="https://www.schema.org/SiteNavigationElement"
                id="menu-item-338"
                className="nav-item"
              >
                <NavLink
                  title="Identification upload"
                  to={SPECTRA_QUALITY_UPLOAD}
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                >
                  <FontAwesomeIcon icon={faArrowUpFromSquare as IconProp} />
                  Spectra Quality
                </NavLink>
              </li>
              <li
                itemScope={true}
                itemType="https://www.schema.org/SiteNavigationElement"
                id="menu-item-337"
                className="nav-item"
              >
                <NavLink
                  title="Price and License"
                  to={ORDERS_ROUTE}
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                >
                  <FontAwesomeIcon icon={faList as IconProp} />
                  Orders
                </NavLink>
              </li>
              <li
                itemScope={true}
                itemType="https://www.schema.org/SiteNavigationElement"
                id="menu-item-336"
                className="nav-item"
              >
                <a
                  title="Upload Guides"
                  href={TUTORIAL_ROUTE}
                  target="_blank"
                  className="nav-link"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faBookOpenCover as IconProp} />
                  Tutorial
                </a>
              </li>
              <li
                  itemScope={true}
                  itemType="https://www.schema.org/SiteNavigationElement"
                  id="menu-item-336"
                  className="nav-item"
              >
                <NavLink
                    title="Price and License"
                    to={ACCOUNT_ROUTE}
                    className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                    }
                >
                  <FontAwesomeIcon icon={faUser as IconProp} />
                  Account
                </NavLink>
              </li>
              <li
                itemScope={true}
                itemType="https://www.schema.org/SiteNavigationElement"
                id="menu-item-335"
                className="nav-item"
              >
                <button
                  title="Logout"
                  type="button"
                  className="btn btn-link nav-link"
                  onClick={handleLogout}
                >
                  <FontAwesomeIcon icon={faArrowRightFromBracket as IconProp} />
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};
