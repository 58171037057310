export const LOGIN_ROUTE = "/login";
export const ORDERS_ROUTE = "/orders";
export const OVERVIEW_ROUTE = "/";
export const TUTORIAL_ROUTE = "https://mabriteccentral.com/upload-guides/";

// identification
export const IDENTIFICATION_UPLOAD = "/identification/upload";
export const IDENTIFICATION_ORDER = "/identification/order/:orderId";
export const IDENTIFICATION_SPECTRA_DETAILS_ROUTE =
  "/identification/spectra/:id";

// spectra quality
export const SPECTRA_QUALITY_UPLOAD = "/spectra-quality/upload";
export const SPECTRA_QUALITY_ORDER = "/spectra-quality/order/:orderId";
export const SPECTRA_QUALITY_SPECTRA_DETAILS_ROUTE =
  "/spectra-quality/spectra/:id";

// mosquito egg
export const MOSQUITO_VECTORS_UPLOAD = "/mosquito-vectors/upload";

export const RESET_PASSWORD_ROUTE = "/reset-password";
export const RESET_PASSWORD_ACTION_ROUTE = "/action/reset-password";

// account
export const ACCOUNT_ROUTE = "/account";
