import React, { useEffect, useState } from "react";
import { Order, Spectra, UserData } from "../../typings";
import { useAuthState } from "react-firebase-hooks/auth";
import {  auth, db } from "../../firebase";
import { generatePath, Link, useNavigate, useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import {
  IDENTIFICATION_ORDER,
  SPECTRA_QUALITY_SPECTRA_DETAILS_ROUTE,
} from "../../Routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation as faTriangleExclamationSolid } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTriangleExclamation as faTriangleExclamationLight } from "@fortawesome/pro-light-svg-icons";
import { ReactComponent as DownloadSvg } from "../../theme/images/download.svg";
import { isSpectraQualityOrder } from "../../utils/is-spectra-quality-order";
import {
  SPECTRA_QUALITY_HINT_MESSAGE,
  SPECTRA_QUALITY_IDENTIFICATION_HINT_MESSAGE,
} from "../../Constants";
import { SpectraQualityRowHeader } from "../../components/SpectraQualityRowHeader";
import {PDFDownloadLink} from "@react-pdf/renderer";
import {SpectraQualityReport} from "../../pdf/SpectraQualityReport";

export const SpectraQualityOrder: React.FC = () => {
  const [order, setOrder] = useState<Order | undefined>(undefined);
  const [isOrderLoading, setIsOrderLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [spectras, setSpectras] = useState<Spectra[]>([]);
  const [user, loading] = useAuthState(auth);
  const [userData, setUserData] = useState<UserData | undefined>(undefined);
  const { orderId } = useParams();
  const navigate = useNavigate();

  // redirect to identification order
  useEffect(() => {
    if (loading) return;
    if (!user) return;
    if (!order) return;

    if (!isSpectraQualityOrder(order.database))
      navigate(generatePath(IDENTIFICATION_ORDER, { orderId: order.orderId }));
  });

  useEffect(() => {
    if (loading) return;
    if (!user) return;

    const collectionRef = collection(db, "spectras");
    const q = query(
      collectionRef,
      where("userId", "==", user.uid),
      where("orderId", "==", orderId),
      orderBy("sample", "asc"),
      orderBy("file", "asc")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      // reset orders when update come in
      const newSpectras: Spectra[] = [];
      querySnapshot.forEach((doc) => {
        // update OrdersList
        const data: Spectra = doc.data() as Spectra;
        newSpectras.push({ ...data, id: doc.id });
      });

      setSpectras(newSpectras);
    });

    return () => unsubscribe();
  }, [user, loading, orderId]);

  useEffect(() => {
    if (loading) return;
    if (!user) return;

    const collectionRef = collection(db, "orders");
    const q = query(
      collectionRef,
      where("userId", "==", user.uid),
      where("orderId", "==", orderId)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.size === 0) setError(`Order not found.`);
      querySnapshot.forEach((doc) => {
        // update OrdersList
        setOrder(doc.data() as Order);
      });

      setIsOrderLoading(false);
    });

    return () => unsubscribe();
  }, [user, loading, orderId]);

  useEffect(() => {
    if (loading) return;
    if (!user) return;

    const docRef = doc(db, "users", user.uid);

    getDoc(docRef).then((docSnap) => {
      if (!docSnap.exists()) return setError("User not found");
      setUserData(docSnap.data() as UserData);
    });
  }, [user, loading]);

  // todo make it not jump
  const progressBarWidth = Math.min(
      Math.max(
          Math.floor((spectras.length / (order?.spectraCount && order?.spectraCount > 0 ? order?.spectraCount + 5 : order?.files.length || 5)) * 100),
          2
      ),
      100
  );
  const progressBar = (
    <div className="progress w-100">
      <div
        className="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        aria-valuenow={progressBarWidth}
        aria-valuemin={0}
        aria-valuemax={order?.spectraCount}
        style={{ width: `${progressBarWidth}%` }}
      />
    </div>
  );

  const SpectraRow = ({
    file,
    sample,
    result,
    nonQualifiedResult,
    id,
    error,
    quality, score, nonQualifiedScore,
  }: Spectra) => {
    return (
      <div className="col-12">
        <div className="detail-results__wrapper bg-white">
          <div className="row gy-20">
            <div className="col-12 col-sm-6 col-lg-3">
              <span className="text-break">
                <span className="d-block d-lg-none fw-bold">Filename:</span>
                {file}
              </span>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <span className="text-break">
                <span className="d-block d-lg-none fw-bold">Sample Name:</span>
                {sample}
              </span>
            </div>
            <div className="col-12 col-sm-6 col-lg-1">
              <span className="text-break">
                <span className="d-block d-lg-none fw-bold">Score:</span>
                <div className="fw-bold">
                  {score && score > 0 ? `${score}%` : ""}
                  {nonQualifiedScore && nonQualifiedScore > 0 ? `${nonQualifiedScore}%` : ""}
                  {score === 0 && nonQualifiedScore === 0 ? "---" : ""}
                </div>
              </span>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <div>
                <span className="d-block d-lg-none fw-bold">
                  Identification:
                </span>
                {result &&
                  result.map((profile, index) => (
                    <div className="result result-qualified" key={index}>
                      <span className="ball" />
                      {profile}
                    </div>
                  ))}

                {nonQualifiedResult &&
                  nonQualifiedResult.map((profile, index) => (
                    <div className="result result-not-qualified" key={index}>
                      <span className="ball" />
                      {profile}
                    </div>
                  ))}

                {!result && !nonQualifiedResult && (
                  <div className="result result-none">
                    <span className="ball" />
                    ---
                  </div>
                )}
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-2">
              <div className="row gy-20">
                <div className="col-12">
                  <div className="d-lg-none fw-bold">Quality:</div>
                  <div className="fw-bold">{quality || "---"}</div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-30">
              <Link
                to={generatePath(SPECTRA_QUALITY_SPECTRA_DETAILS_ROUTE, {
                  id: id,
                })}
                className="btn btn-primary"
              >
                {error && (
                  <FontAwesomeIcon
                    icon={faTriangleExclamationSolid as IconProp}
                    className="mr-5"
                  />
                )}
                more details
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // loading state
  if (isOrderLoading) {
    return (
      <div className="container min-vh-100 align-items-center">
        <div
          className="position-absolute top-50 start-50 spinner-border text-primary"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error && !order) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <FontAwesomeIcon icon={faTriangleExclamationLight as IconProp} />{" "}
            {error}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6 mb-40 mb-lg-80">
          <div>
            <span>
              Order ID: <strong>{order?.orderId}</strong>
            </span>
          </div>
          <div>
            <span>
              Order date:{" "}
              <strong>
                {order &&
                  new Date(order?.uploadedAt?.seconds * 1000).toLocaleString()}
              </strong>
            </span>
          </div>
          <div>
            <span>
              Number of spectras uploaded:{" "}
              <strong>{order?.spectraCount}</strong>
            </span>
          </div>
          <div>
            <span>
              Database used: <strong>{order?.database}</strong>
            </span>
          </div>
        </div>
        <div className="col-12 col-lg-6 mb-40 mb-lg-80">
          <div className="d-flex justify-content-lg-end gap-30 downloads-wrapper">
            {order?.status === "DONE" && userData && spectras.length < 500 && (
                <PDFDownloadLink document={<SpectraQualityReport spectras={spectras} order={order} userData={userData} />} fileName={`report_${order?.orderId}.pdf`}>
                  {({ blob, url, loading, error }) => {
                    if(error) window.alert("Failed to generate the PDF.");

                    return (
                        (
                            <span>
                              { loading ?  <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                              /> : <DownloadSvg />}
                              <span>PDF export</span>
                            </span>
                        )
                    )
                  }}
                </PDFDownloadLink>
            )}
          </div>
        </div>

        {/* Order error */}
        {order?.error && (
          <div className="col-12 mb-40">
            <span>
              {" "}
              <FontAwesomeIcon
                icon={faTriangleExclamationLight as IconProp}
              />{" "}
              Something went wrong while processing the Files.
            </span>
          </div>
        )}

        <div className="col-12 mb-40">
          {order?.status === "RUNNING" && progressBar}
        </div>
        <div className="col-12">
          {spectras.length > 0 && <SpectraQualityRowHeader />}
        </div>
        {spectras.length > 0 &&
          spectras.map((spectra, index) => (
            <SpectraRow {...spectra} key={index} />
          ))}
        {spectras.length > 0 && (
          <div className="col-8 offset-2 text-center">
            <div>{SPECTRA_QUALITY_IDENTIFICATION_HINT_MESSAGE}</div>
            <div>{SPECTRA_QUALITY_HINT_MESSAGE}</div>
          </div>
        )}
      </div>
    </div>
  );
};
