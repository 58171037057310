import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import {analytics, auth, createMosquitoVectorOrder, db} from "../../firebase";
import { generatePath, useNavigate } from "react-router-dom";
import {
  collection,
  onSnapshot,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { logEvent } from "firebase/analytics";
import { useDropzone } from "react-dropzone";
import {IDENTIFICATION_ORDER, TUTORIAL_ROUTE} from "../../Routes";
import prettyBytes from "pretty-bytes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown, faTimes } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import accept from "attr-accept";
import {BrukerSelector} from "../../components/BrukerSelector";
import {MachineType} from "../../typings";
import {MachineTypeSelector} from "../../components/upload/MachineTypeSelector";
import {UploadErrorMessages} from "../../components/upload/UploadErrorMessages";
import {getAcceptedFileType} from "../../utils/get-accepted-file-type";
import {useActiveLicence} from "../../hooks/useActiveLicence";


export const MosquitoVectorsUpload: React.FC = () => {
  const [machineType, setMachineType] = useState<MachineType>();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [customerReference, setCustomerReference] = useState<string>();
  const [submitError, setSubmitError] = useState("");
  const [isBrukerSelectorModalOpen, setIsBrukerSelectorModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const [hasActiveLicence] = useActiveLicence()

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      logEvent(analytics, "user_added_files");
      setSelectedFiles([...selectedFiles, ...acceptedFiles]);
    },
    [selectedFiles]
  );

  const acceptedFileType = getAcceptedFileType(machineType);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const handleClear = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setMachineType(undefined);
    setSelectedFiles([]);
  };

  const handleRemoveFile = (file: File) => () => {
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles.splice(newSelectedFiles.indexOf(file), 1);
    setSelectedFiles(newSelectedFiles);
  };

  const handleSubmit = async (e: FormEvent) => {
    if(!machineType) return;
    setIsUploading(true);
    e.preventDefault();

    try {
      logEvent(analytics, "user_submitted_mosquito_vectors_order", {
        filesCount: selectedFiles.length,
        machineType,
        selectedFiles,
      });
      const result = await createMosquitoVectorOrder(machineType, selectedFiles, customerReference );
      console.log("order started ", result);
      setTimeout(() => {
        navigate(generatePath(IDENTIFICATION_ORDER, { orderId: result.data }));
      }, 5000);
    } catch (e) {
      setIsUploading(false)
      setSubmitError("We have encountered unexpected problems. Please contact us at contact.mabriteccentral@mabritec.com.")
      logEvent(analytics, "user_failed_to_submit", {
        error: e
      });
      console.error(e);
    }
  };

  const brukerImportFile = (file: File) => {
    setSelectedFiles([...selectedFiles, file]);
    setIsBrukerSelectorModalOpen(false);
    setMachineType("bruker");
  }

  const handleCloseBrukerSelectorModal = () => {
    setIsBrukerSelectorModalOpen(false);
  }

  const files = selectedFiles.map((file, i) => (
    <div className="dz-details" key={i}>
      <div className="dz-filename text-break">
        <span>{file.name}</span>
      </div>
      <div className="dz-size text-nowrap">{prettyBytes(file.size)}</div>
      <div className="dz-remove" onClick={handleRemoveFile(file)}>
        <FontAwesomeIcon icon={faTimes as IconProp} />
      </div>
    </div>
  ));

  const disableSubmit =
    !selectedFiles
      .map((file) => accept(file, acceptedFileType))
      .every(Boolean) ||
    selectedFiles.length === 0 ||
    !machineType || !hasActiveLicence ||
    selectedFiles.map((file) => file.size > 30 * 1024 * 1024).every(Boolean) ||
    isUploading ||
      selectedFiles.length > 500

  return (
    <div className="identification-upload container">
      <BrukerSelector importFile={brukerImportFile} closeModal={handleCloseBrukerSelectorModal} isOpen={isBrukerSelectorModalOpen} />

      <div className="row">
        <div className="col-12 offset-lg-2 col-lg-8 mb-60">
          <div className="fw-bolder mb-20">Mosquito vectors</div>
          <div>
            A MALDI-TOF MS based identification tool for the identification of mosquito vector eggs.
          </div>
        </div>
        <div className="col-12 offset-lg-2 col-lg-8">
          <form
            encType="multipart/form-data"
            id="uploadForm"
            onSubmit={handleSubmit}
          >
            <MachineTypeSelector machineType={machineType} setMachineType={setMachineType} availableMachineTypes={["bruker", "shimadzuAscii", "vitekMSAscii", "vitekMSMzml"]} />

            <div className="row">
              <div className="col-xl-5 col-12 mb-30">
                <div className="form-group">
                  <label htmlFor="reference" className="fw-bolder mb-20">
                    02. Add customer reference (optional):
                  </label>

                  <input
                      className="form-control"
                      type="text"
                      id="customerReference"
                      value={customerReference}
                      maxLength={30}
                      title="maximum 30 characters"
                      onChange={(event) => setCustomerReference(event.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="mb-20">
              <div className="d-flex justify-content-between gap-40 mb-20">
                <label htmlFor="files" className="fw-bolder">
                  03. Upload files:
                </label>
                <div>
                  <a href={TUTORIAL_ROUTE} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon
                      icon={faFileArrowDown as IconProp}
                      className="mr-10"
                    />
                    upload instructions
                  </a>
                </div>
              </div>
              <div className="dropzone" id="dropzone">
                {files}
                { /* total files selected */}
                {selectedFiles.length > 0 && (
                    <div className="dz-details dz-details__total">
                      <div className="dz-filename text-break fw-bold">
                        <span>{selectedFiles.length} {selectedFiles.length > 1 ? "files" : "file"} selected</span>
                      </div>
                    </div>
                )}
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="dz-message">
                    <p>
                      <strong>Drag and Drop files here,</strong>
                      <br />
                      or click to select them from your computer
                      <br />
                      or select your bruker compass experiment <button className="btn btn-link" onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      setIsBrukerSelectorModalOpen(true)
                    }}>here</button>
                    </p>
                    <p className="small">(file size must not exceed 30MB)</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex gap-30 mb-20">
              <button
                className="btn btn-outline-primary"
                onClick={(e) => handleClear(e)}
              >
                Clear
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={disableSubmit}
              >
                {isUploading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Send"
                )}
              </button>
            </div>
            <UploadErrorMessages selectedFiles={selectedFiles} hasActiveLicence={hasActiveLicence} machineType={machineType} acceptedFileType={acceptedFileType} isUploading={isUploading} submitError={submitError} />
          </form>
        </div>
      </div>
    </div>
  );
};
