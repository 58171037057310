import { v4 as uuidv4 } from "uuid";
import { auth, startSpectraQualityOrder, uploadFiles } from "./index";
import {getMachineType} from "../utils/get-machine-type";
import {MachineType} from "../typings";

export const createSpectraQualityOrder = async (
  machineType: MachineType,
  files: File[]
) => {
  const machine = getMachineType(machineType);

    if(!machine) throw new Error("No machine found for: " + machineType);
  const user = auth.currentUser;
  if (!user) throw new Error("no user");
  const orderId = uuidv4();

  const filePaths = (await uploadFiles(files, orderId)) as string[];
  console.log("files uploaded: ", filePaths);
  return startSpectraQualityOrder({ files: filePaths, orderId, machineType: machine });
};
