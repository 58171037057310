import {MachineType} from "../typings";

const extensions: { [K in MachineType]: string } = {
    bruker: ".zip",
    vitekMSMzml: ".mzml",
    shimadzuAscii: ".txt",
    vitekMSAscii: ".txt",
    clover: ".zip",
};

export const getAcceptedFileType = (machineType?: MachineType): string => {
    return machineType ? extensions[machineType] : "";
};