import React from "react";
import accept from "attr-accept";

type Props = {
    selectedFiles: File[];
    hasActiveLicence: boolean;
    machineType?: string;
    acceptedFileType: string;
    isUploading: boolean;
    submitError: string;
}

export const UploadErrorMessages: React.FC<Props> = ({ selectedFiles, hasActiveLicence, machineType, isUploading, submitError, acceptedFileType }) => {
    const notEnoughCreditsMessage = !hasActiveLicence && (
        <p>
            No active license found. Order a new licence{" "}
            <a href="https://mabriteccentral.com/order" rel="noreferrer">
                here
            </a>
            .
        </p>
    );
    const brukerMachineTypeMessage = machineType === "bruker" &&
        !selectedFiles
            .map((file) => accept(file, acceptedFileType))
            .every(Boolean) && (
            <p>For Bruker Biotyper only files of type .zip are allowed.</p>
        );

    const cloverMachineTypeMessage = machineType === "clover" &&
        !selectedFiles
            .map((file) => accept(file, acceptedFileType))
            .every(Boolean) && (
            <p>For Clover BioSoft only files of type .zip are allowed.</p>
        );

    const vitekMSMachineTypeMessage = machineType === "vitekMSMzml" &&
        !selectedFiles
            .map((file) => accept(file, acceptedFileType))
            .every(Boolean) && (
            <p>For Vitek MS (mzml) only files of type .mzml are allowed.</p>
        );

    const shimadzuMachineTypeMessage = machineType === "shimadzuAscii" &&
        !selectedFiles
            .map((file) => accept(file, acceptedFileType))
            .every(Boolean) && (
            <p>For Shimadzu 8020 / 8030 (ascii) only files of type .txt are allowed.</p>
        );

    const vitekMSAsciiMachineTypeMessage = machineType === "vitekMSAscii" &&
        !selectedFiles
            .map((file) => accept(file, acceptedFileType))
            .every(Boolean) && (
            <p>For Vitek MS Prime (ascii) only files of type .txt are allowed.</p>
        );

    const noMachineTypeSelected = selectedFiles.length !== 0 &&
        !machineType && <p>Please select your machine.</p>;

    const fileToBig = selectedFiles.length !== 0 &&
        selectedFiles
            .map((file) => file.size > 30 * 1024 * 1024)
            .every(Boolean) && <p>One or more file(s) exceed 30MB.</p>;

    const toMannyFiles = selectedFiles.length > 500 && <p>Maximum 500 files allowed.</p>;

    return (
        <div className="upload-error-messages text-danger">
            {submitError}
            {noMachineTypeSelected}
            {notEnoughCreditsMessage}
            {brukerMachineTypeMessage}
            {vitekMSMachineTypeMessage}
            {shimadzuMachineTypeMessage}
            {vitekMSAsciiMachineTypeMessage}
            {cloverMachineTypeMessage}
            {fileToBig}
            {toMannyFiles}
        </div>
    )
}