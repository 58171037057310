import React from "react";
import {Spectra} from "../typings";
import {getSpectraWarning} from "../utils/get-spectra-warning";
import {generatePath, Link} from "react-router-dom";
import {IDENTIFICATION_SPECTRA_DETAILS_ROUTE} from "../Routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTriangleExclamation as faTriangleExclamationSolid} from "@fortawesome/pro-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";

type Props = Spectra;

export const SpectraRow: React.FC<Props> = ({
                                         file,
                                         sample,
                                         result,
                                         score,
                                         nonQualifiedScore,
                                         nonQualifiedResult,
                                         id,
                                         error,
                                         databaseUsed,
                                         database,
                                         masses,
                                     }: Spectra) => {
    const hasWarning = getSpectraWarning(databaseUsed || database);

    return (
        <div className="col-12">
            <div className="detail-results__wrapper bg-white">
                <div className="row gy-20">
                    <div className="col-12 col-sm-6 col-lg-2">
              <span className="text-break">
                <span className="d-block d-lg-none fw-bold">Filename:</span>
                  {file}
              </span>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-2">
              <span className="text-break">
                <span className="d-block d-lg-none fw-bold">Sample Name:</span>
                  {sample}
              </span>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-1">
                        <div>
                  <span className="d-block d-lg-none fw-bold">
                    Datacount:
                  </span>
                            <div className="fw-bold">
                            {masses && masses.length}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-1">
                        <div>
                <span className="d-block d-lg-none fw-bold">
                  Qualified score:
                </span>
                            <div className="fw-bold">
                                {score && score > 0 ? `${score}%` : "---"}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                        <div>
                <span className="d-block d-lg-none fw-bold">
                  Qualified result:
                </span>
                            {result ? (
                                result.map((profile, index) => (
                                    <div className="result result-qualified" key={index}>
                                        <span className="ball"></span>
                                        {profile}
                                    </div>
                                ))
                            ) : (
                                <div className="result result-qualified">
                                    ---
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-1">
                        <div className="row gy-20">
                            <div className="col-12">
                                <div className="d-lg-none fw-bold">Non-Qualified score:</div>
                                <div className="fw-bold">
                                    {nonQualifiedScore && nonQualifiedScore > 0 ? `${nonQualifiedScore}%` : "---"}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-2">
                        <div className="row gy-20">
                            <div className="col-12">
                                <div className="d-lg-none fw-bold">Non-Qualified result:</div>
                                {nonQualifiedResult ? (
                                    nonQualifiedResult.map((profile, index) => (
                                        <div className={classNames({
                                            "result": true,
                                            "result-not-qualified": nonQualifiedScore && nonQualifiedScore >= 70,
                                            "result-none": nonQualifiedScore && nonQualifiedScore < 70,
                                        })} key={index}>
                                            <span className="ball"></span>
                                            {profile}
                                        </div>
                                    ))
                                ) : (
                                    <div className="result result-not-qualified">
                                        ---
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-30">
                        <Link
                            to={generatePath(IDENTIFICATION_SPECTRA_DETAILS_ROUTE, {
                                id: id,
                            })}
                            className="btn btn-primary"
                        >
                            {(error || hasWarning) && (
                                <FontAwesomeIcon
                                    icon={faTriangleExclamationSolid as IconProp}
                                    className="mr-5"
                                />
                            )}
                            more details
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}