import { axisBottom, axisLeft, scaleBand, scalePoint, select } from "d3";
import React, { useEffect, useRef } from "react";
import useResizeObserver from "../hooks/useRezizeObserver";

type Props = {
  highestPeakMz: number;
  meanPPMError: number | false;
  nrPeaks: number;
  nrSubunitsMatched: number;
  quality: string | false;
};

const MARGIN = { top: 30, right: 40, bottom: 80, left: 60 };

const getHighestPeakMzRating = (input: number) => {
  if (input > 15000) return "excellent";
  if (input > 12300 && input <= 15000) return "good";
  if (input > 10000 && input <= 12300) return "moderate";
  if (input <= 10000) return "poor";

  return "";
};

const getMeanPPMErrorRating = (input: number | false) => {
  // when coli is not found, ppm error cant be calculated -> fale
  if (input === false) return "poor";

  if (input < 300) return "excellent";
  if (input < 600 && input >= 300) return "good";
  if (input < 800 && input >= 600) return "moderate";
  if (input >= 800) return "poor";

  return "";
};

const getNrPeaksRating = (input: number) => {
  if (input > 100) return "excellent";
  if (input > 80 && input <= 100) return "good";
  if (input > 30 && input <= 80) return "moderate";
  if (input <= 30) return "poor";

  return "";
};

const getNrSubunitsMatchedRating = (input: number) => {
  if (input > 25) return "excellent";
  if (input > 20 && input <= 25) return "good";
  if (input > 15 && input <= 20) return "moderate";
  if (input <= 15) return "poor";

  return "";
};

export const SpectraQualityBarChart: React.FC<Props> = ({
  highestPeakMz,
  nrPeaks,
  nrSubunitsMatched,
  meanPPMError,
  quality,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const dimensions = useResizeObserver(wrapperRef);

  const { width = 0, height = 0 } = dimensions! || { width: 0, height: 0 };

  const boundsWidth = width - MARGIN.right - MARGIN.left;
  const boundsHeight = height - MARGIN.top - MARGIN.bottom;

  useEffect(() => {
    if (!svgRef.current) return;
    const svg = select(svgRef.current);
    const svgContent = svg.select(".content");

    const data = [
      { category: "massrange", value: getHighestPeakMzRating(highestPeakMz) },
      { category: "PPM error", value: getMeanPPMErrorRating(meanPPMError) },
      { category: "number of peaks", value: getNrPeaksRating(nrPeaks) },
      {
        category: "matched subunits",
        value: getNrSubunitsMatchedRating(nrSubunitsMatched),
      },
    ];

    const xScale = scaleBand()
      .range([0, boundsWidth])
      .domain([
        "massrange",
        "PPM error",
        "number of peaks",
        "matched subunits",
      ]);

    const yScale = scalePoint()
      .range([boundsHeight, 0])
      .domain(["", "poor", "moderate", "good", "excellent"])
      .padding(0.1);
    // remove old peaks on dimension change
    svgContent.selectAll("*").remove();

    // lines category

    svgContent
      .selectAll(".lines-category")
      .data(["excellent", "good", "moderate", "poor"])
      .enter()
      .append("line")
      .attr("x1", 0)
      .attr("x2", boundsWidth)
      .attr("y1", (value) => yScale(value) as number)
      .attr("y2", (value) => yScale(value) as number)
      .attr("stroke", (value) => (value === quality ? "red" : "grey"))
      .attr("stroke-dasharray", (value) => (value === quality ? 0 : 5.5))
      .attr("stroke-width", 0.6);

    svgContent
      .selectAll(".bars")
      .data(data)
      .enter()
      .append("rect")
      .attr(
        "x",
        ({ category }) =>
          (xScale(category) as number) + xScale.bandwidth() / 2.5
      )
      .attr("y", ({ value }) => (yScale(value) as number) - 2)
      .attr("width", xScale.bandwidth() / 5)
      .attr("height", ({ value }) =>
        Math.abs(boundsHeight - (yScale(value) as number) + 2)
      )
      .attr("fill", "#d3df00");

    // axes
    const xAxis = axisBottom(xScale);
    svg
      .select<SVGSVGElement>(".x-axis")
      .attr("transform", `translate(0, ${boundsHeight})`)
      .call(xAxis)
      .selectAll("text")
      .attr("transform", "translate(-10,0)rotate(-45)")
        .attr("font-size", 12)
      .style("text-anchor", "end");

    const yAxis = axisLeft(yScale);
    svg
      .select<SVGSVGElement>(".y-axis")
      .call(yAxis)
      .selectAll("text")
      .attr("font-size", (value) => (value === quality ? 12 : 11))
      .attr("font-weight", (value) =>
        value === quality ? "bolder" : "normal"
      );
  }, [
    dimensions,
    boundsWidth,
    boundsHeight,
    height,
    width,
    highestPeakMz,
    nrPeaks,
    nrSubunitsMatched,
    meanPPMError,
    quality,
  ]);

  return (
    <div ref={wrapperRef} style={{ marginBottom: "2rem", height: "400px" }}>
      <svg
        ref={svgRef}
        width={width}
        height={height}
        style={{ display: "inline-block" }}
      >
        <defs>
          <clipPath id="sepctra-quality-bar-chart">
            <rect x="0" y="0" width="100%" height="100%" />
          </clipPath>
        </defs>
        <g
          className="content"
          clipPath={`url(#sepctra-quality-bar-chart`}
          width={boundsWidth}
          height={boundsHeight}
          transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
        />
        <g
          width={boundsWidth}
          height={boundsHeight}
          transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
        >
          <g className="x-axis" />
          <g className="y-axis" />
        </g>
      </svg>
    </div>
  );
};
